import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Home, Products, About, Contact, Shop, Privacy, Cookies, Admin, Login } from './pages/index';
import { getCookie, setCookie } from './functionality/cookies';
// Components
import MainPage from './pages/MainPage'

// Styling
import './App.css';

let defaultText: { [key: string]: any } = require('./assets/text.json');

/**
 * 
 * @returns Which language should be the default language depending on browser options and previous choice
 */
function getDefaultLanguage(): string {
    // Get the language of the browser and possibly user set language
    let browserLanguage: string = navigator.language;
    // Clean up for the correct format
    if (browserLanguage === 'en-US' || browserLanguage === 'en-GB' || browserLanguage === 'en') {
      browserLanguage = 'en';
    } else {
      browserLanguage = 'sv';
    }
  
    const choosenLanguage: string = getCookie('language');
  
    // If a choosen language exists, use that, otherwise try the browser language.
    // If no language exists anywhere, default to Swedish
    let defaultLanguage: string = 'sv';
    if (choosenLanguage !== '') {
      defaultLanguage = choosenLanguage;
    } else if (browserLanguage !== '') {
      defaultLanguage = browserLanguage;
    }

    return defaultLanguage;
}

function App() {
  const [language, setLanguage] = useState(getDefaultLanguage());
  const [text, setText] = useState(defaultText[language]); // All the text all over the web page

  function handleSetLanguage(choice: string) {
    if (getCookie('cookie-accept') === 'true') {
      setCookie('language', choice, 360);
    } 
    setLanguage(choice);
  } 

  // Re-direct if www is missing in url
  useEffect(() => {
    // Re-direct non "www" into "www". Example: testhemsida.bogfelts.com => www.testhemsida.bogfelts.com
    if (window.location.hostname === 'infragross.com' || window.location.hostname === 'https://infragross.com') {
      window.location.replace(`${process.env.REACT_APP_BACKEND_URL}${window.location.pathname}`);  
    }
  }, []);

  // Fetch text from public folder when user is changing language - defaults to a static stored json file
  useEffect(() => {
    fetch('/text.json')
        .then(response => response.json())
        .then(data => setText(data[language]))
        .catch(error => console.error('Error fetching JSON:', error));
}, [language]);

  // Scroll to location when entering a new url
  function ScrollToLocation() {
    // First scroll to the top
    window.scrollTo(0, 0);

    // Then scoll into possible location
    const { pathname, hash } = useLocation();
  
    useEffect(() => {
      // If there's a hash, scroll to it
      if (hash) {
        const id = hash.substring(1) // Remove the '#' character

        if (id !== undefined && id !== '') {
          const idFixed = id.split('?')[0];

          const element = document.getElementById(idFixed);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    }, [pathname, hash]);
  
    return null;
  }

  return (
    <>
    <ScrollToLocation />
    <Routes>
      <Route path='/' element={<MainPage language={language} text={text} onSetLanguage={handleSetLanguage} />}>
        <Route index element={<Home text={text.home} />} />
        <Route path='products' element={<Products text={text.products} />} />
        <Route path='about' element={<About text={text.about} />} />
        <Route path='contact' element={<Contact language={language} text={text.contact} />} />

        <Route path='privacy' element={<Privacy text={text.privacy} />} />
        <Route path='cookies' element={<Cookies text={text.privacy} />} />
      </Route>
    <Route path='/admin' element={<Admin />} />
    <Route path='/login' element={<Login />} />
    </Routes>
    </>
  );
}

export default App;
